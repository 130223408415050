// Entry point for the build script in your package.json
import smoothscroll from "smoothscroll-polyfill";
// kick off the polyfill!
smoothscroll.polyfill();

import Util from "./utilities";
window.Util = Util;

import "@hotwired/turbo-rails";
import "ninja-keys";
import tippy from "tippy.js";

// begin popper
import { createPopper } from "@popperjs/core";
window.Popper = {
  createPopper: createPopper,
};
// end popper

// begin alpine
import "./alpines";
import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import intersect from "@alpinejs/intersect";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";

Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(mask);

window.Alpine = Alpine;
Alpine.start();
// end alpine

// begin stimulus
import "./controllers";
// end stimulus
// begin turbo confirm
import TurboConFirmOverride from "./turbo_confirm_override";
Turbo.setConfirmMethod((message, element) => {
  return TurboConFirmOverride.handle(message, element);
});
// end turbo confirm

//begin web service worker
const WORKER_VERSION = "v1";
const CACHE_KEY_WORKER = ":sw-cache-worker:version";
const actual_worker_version = localStorage.getItem(CACHE_KEY_WORKER);
const isWorkerCurrent = actual_worker_version === WORKER_VERSION;

console.log("[PWA]", "Hello from app/javascript/application.js!");
if (navigator.serviceWorker) {
  if (!isWorkerCurrent) {
    console.log("[PWA]", "unregistering old service worker");
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
  console.log("[PWA] Registering service worker", "service-worker.js");
  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then(function (registration) {
      console.log("[PWA]", "Service worker registered!");
      console.log(registration);
      localStorage.setItem(CACHE_KEY_WORKER, WORKER_VERSION);
    })
    .catch(function (error) {
      console.log("[PWA]", "Service worker registration failed:", error);
    });
} else {
  console.log("no service worker");
}
// end web service worker
