export default class {
  static pushQueryString(changes) {
    const url = new URL(location);
    Object.keys(changes).forEach((key) => {
      url.searchParams.set(key, changes[key]);
    });
    history.pushState({}, "", url);
  }
  static getQueryString(key) {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(key);
  }
  static debounce(func, delay, { leading } = {}) {
    let timerId;

    return (...args) => {
      if (!timerId && leading) {
        func(...args);
      }
      clearTimeout(timerId);

      timerId = setTimeout(() => func(...args), delay);
    };
  }

  static throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
      var context = scope || this;

      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  }
}
