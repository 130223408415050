import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notifications--mark-read"
export default class extends Controller {
  static values = {
    url: String,
    remove: Boolean,
  };

  connect() {
    console.log("Connected to notifications--mark-read", this.urlValue);
  }

  onClick(event) {
    const csrfToken = document.getElementsByName("csrf-token")[0].content;
    if (this.removeValue) {
      this.element.classList.add("hidden");
    }
    fetch(this.urlValue, {
      method: "PATCH",
      body: JSON.stringify({ action: "read" }),
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        if (this.removeValue) {
          this.element.remove();
        }
      })
      .catch(() => {
        if (this.removeValue) {
          this.element.classList.remove("hidden");
        }
      });
  }
}
