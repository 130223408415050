// Example usage:
// <div data-controller="tooltip" data-tooltip-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["on", "off"];

  connect() {}
  on() {
    if (this.hasOnClass) {
      this.element.classList.add(this.onClass);
    }
    if (this.hasOffClass) {
      this.element.classList.remove(this.offClass);
      // console.log("removing off class", this.offClass, this.element.classList, this.element);
    }
    this.__is_on = true;
  }
  off() {
    if (this.hasOnClass) {
      this.element.classList.remove(this.onClass);
      // console.log("removing on class", this.onClass, this.element.classList, this.element);
    }
    if (this.hasOffClass) {
      this.element.classList.add(this.offClass);
    }
    this.__is_on = false;
  }
  toggle(event) {
    let value = !this.__is_on;
    if (event === true || event === false) {
      // console.log("FALLING BACK TO EVENT!", event);
      value = event;
    }
    // console.log("toggle TOGGLE", value, this.__is_on);
    if (value) {
      this.on();
    } else {
      this.off();
    }
  }
}
