import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="input-counter"
export default class extends Controller {
  static targets = ["input", "display"];
  static values = {
    max: Number,
  };
  connect() {
    console.log("Input counter controller connected");
    this.call();
  }

  call(event) {
    console.log("Input counter controller called");
    const size = this.inputTarget.value.length;

    console.log(`Input counter controller: ${size}/${this.max}`);
    if (this.maxValue) {
      this.displayTarget.innerText = `${size} / ${this.maxValue}`;
    } else {
      this.displayTarget.innerText = size.toString();
    }
  }
}
