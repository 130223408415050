import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Utilities from "../utilities";
import TimedSet from "../lib/timed_set";

// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!

const PREVIOUS_SEARCHES = new TimedSet(1000 * 60 * 5);

export default class extends Controller {
  static targets = [
    "palette",
    "svgAlerts",
    "svgBuilding",
    "svgItem",
    "svgKit",
    "svgService",
    "svgCollapse",
    "svgContact",
    "svgFavorites",
    "svgHome",
    "svgInventory",
    "svgReport",
    "svgRolodex",
    "svgSearch",
    "svgUnknown",
    "svgUtility",
    "svgCategory",
    "svgWarehouse",
  ];

  initialize() {
    this.searchFor = Utilities.throttle(this.searchFor.bind(this), 500);
    this.searchFor = Utilities.debounce(this.searchFor, 100);
    this.paletteTarget.data = [
      {
        id: "open-search",
        title: "Search",
        icon: this.svgSearchTarget.innerHTML,
        hotkey: "ctrl+f",
        handler: () => {
          Turbo.visit("/search");
        },
      },
      {
        id: "open-home",
        title: "Home",
        hotkey: "ctrl+1",
        section: "Navigation",
        icon: this.svgHomeTarget.innerHTML,
        handler: () => {
          Turbo.visit("/");
        },
      },
      {
        id: "open-inventory",
        title: "Inventory",
        section: "Navigation",
        hotkey: "ctrl+2",
        icon: this.svgInventoryTarget.innerHTML,
        handler: () => {
          Turbo.visit("/inventory");
        },
      },
      {
        id: "open-rolodex",
        title: "CRM",
        section: "Navigation",
        hotkey: "ctrl+3",
        icon: this.svgRolodexTarget.innerHTML,
        handler: () => {
          Turbo.visit("/rolodex");
        },
      },
      {
        id: "open-reports",
        title: "Reports",
        section: "Navigation",
        hotkey: "ctrl+4",
        icon: this.svgReportTarget.innerHTML,
        handler: () => {
          Turbo.visit("/reports");
        },
      },
      {
        id: "open-favorites",
        title: "Favorites",
        icon: this.svgFavoritesTarget.innerHTML,
        section: "Navigation",
        handler: () => {
          const event = new CustomEvent("slideout-open-favorites");
          window.dispatchEvent(event);
        },
      },
      {
        id: "open-alerts",
        title: "Alerts",
        section: "Navigation",
        hotkey: "ctrl+a",
        icon: this.svgAlertsTarget.innerHTML,
        handler: () => {
          const event = new CustomEvent("slideout-open-alerts");
          window.dispatchEvent(event);
        },
      },
      {
        id: "action-toggle-sidebar",
        title: "Toggle Sidebar",
        section: "Utility",
        icon: this.svgCollapseTarget.innerHTML,
        handler: () => {
          const elem = document.getElementById("aura-sidebar-collapse");
          if (elem) {
            elem.click();
          } else {
            console.log("sidebar not found");
          }
        },
      },
    ];

    fetch("/favorites.json")
      .then((response) => response.json())
      .then((body) => {
        const newData = [];
        body.data.forEach((row) => {
          newData.push({
            id: row.id,
            title: row.title,
            section: "Favorites",
            icon: this.getIcon(row.iconName),
            handler: () => {
              Turbo.visit(row.url);
            },
          });
        });

        const currentData = this.paletteTarget.data.filter((item) => item.section !== "Favorites");
        this.paletteTarget.data = [];

        const finalData = [...currentData, ...newData];
        this.paletteTarget.data = finalData;
      });

    this.paletteTarget.addEventListener("change", this.onChange.bind(this));
    this.paletteTarget.addEventListener("selected", this.onSelected.bind(this));
    window.addEventListener("command-palette-open", this.open.bind(this));
  }

  getIcon(name) {
    if (name === "contact") {
      return this.svgContactTarget.innerHTML;
    } else if (name === "building") {
      return this.svgBuildingTarget.innerHTML;
    } else if (name === "warehouse") {
      return this.svgWarehouseTarget.innerHTML;
    } else if (name === "product-service") {
      return this.svgServiceTarget.innerHTML;
    } else if (name === "product-item") {
      return this.svgItemTarget.innerHTML;
    } else if (name === "product-kit") {
      return this.svgKitTarget.innerHTML;
    } else if (name === "category") {
      return this.svgCategoryTarget.innerHTML;
    } else {
      return this.svgUnknownTarget.innerHTML;
    }
  }

  open() {
    this.paletteTarget.open();
  }

  onChange(event) {
    console.log(event.detail);

    if (event.detail.actions.length <= 20) {
      this.searchFor(event.detail.search);
    }
  }

  onSelected(event) {}

  searchFor(search) {
    if (!PREVIOUS_SEARCHES.add(search)) {
      return;
    }
    fetch("/search.json?" + new URLSearchParams({ q: search }), {
      method: "GET",
    })
      .then((response) => response.json())
      .then((body) => {
        console.log("Search Results: ", body);
        const newData = [];
        body.data.forEach((row) => {
          newData.push({
            id: row.id,
            title: row.title,
            section: row.iconName, // TODO: change this to section
            icon: this.getIcon(row.iconName),
            handler: () => {
              Turbo.visit(row.url);
            },
          });

          const newIds = newData.map((item) => item.id);

          const currentData = this.paletteTarget.data.filter((item) => !newIds.includes(item.id));
          this.paletteTarget.data = [];

          const finalData = [...currentData, ...newData];
          this.paletteTarget.data = finalData;
        });
      });
  }
}
