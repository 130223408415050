// Example usage:
// <div data-controller="tooltip" data-tooltip-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus";

const COLLAPSED_CONTROLLER_KEY = "collapsed_controller";

export default class extends Controller {
  static values = {
    open: Boolean,
  };
  static outlets = ["toggle"];

  connect() {
    this.__is_collapsed = this.toBool(localStorage.getItem(COLLAPSED_CONTROLLER_KEY)) || false;

    setTimeout(() => {
      this.set(this.__is_collapsed);
    }, 0);
  }

  open() {
    this.toggleOutlets.forEach((outlet) => {
      outlet.on();
    });
  }

  close() {
    this.toggleOutlets.forEach((outlet) => {
      outlet.off();
    });
  }

  set(value) {
    const event = new CustomEvent("sidebar-collapse", { detail: { collapsed: value } });
    window.dispatchEvent(event);

    this.toggleOutlets.forEach((outlet) => {
      if (value) {
        outlet.on();
      } else {
        outlet.off();
      }
    });
  }

  toggle() {
    this.__is_collapsed = !this.__is_collapsed;
    localStorage.setItem(COLLAPSED_CONTROLLER_KEY, this.__is_collapsed);
    this.set(this.__is_collapsed);
  }

  toBool(value) {
    if (typeof value === "string") {
      return value === "true";
    } else {
      return !!value;
    }
  }
}
