import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link-to-focus"
export default class extends Controller {
  static values = {
    target: String,
  };
  connect() {
    console.log("connecting link-to-focus", this.element, this.targetValue);
    this.boundOnClick = this.onClick.bind(this);
    this.element.addEventListener("click", this.boundOnClick);
  }

  disconnect() {
    this.element.removeEventListener("click", this.boundOnClick);
  }

  onClick(event) {
    event.preventDefault();

    const target = document.getElementById(this.targetValue);
    target.focus();
  }
}
