export default class {
  constructor(duration) {
    this.__duration = duration;
    this.__data = {};
  }

  add(val) {
    if (this.has(val)) {
      return false;
    } else {
      this.__data[val] = Date.now();
      return true;
    }
  }

  clear() {
    this.__data = {};
  }

  delete(val) {
    delete this.__data[val];
  }

  has(val) {
    return this.__data[val] >= Date.now() - this.__duration;
  }

  values() {
    return Object.values(this.__data);
  }

  keys() {
    return Object.keys(this.__data);
  }

  entries() {
    return Object.entries(this.__data);
  }

  forEach(callbackFn) {
    this.__data.forEach(callbackFn);
  }
}
