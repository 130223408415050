import { createPopper } from "@popperjs/core";

export default class {
  static init() {
    return function () {
      this.$data.popper = createPopper(this.$refs.button, this.$refs.panel, {
        placement: "auto",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ],
      });

      this.$watch("open", (value) => {
        this.$nextTick(() => {
          this.$data.popper.update();
        });
      });

      console.log("********* popper init *********", {
        popper: this.$data.popper,
        button: this.$refs.button,
        panel: this.$refs.panel,
      });
    };
  }
}
