import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source"];

  connect() {
    console.log("clipboard controller connected");
  }

  copy(e) {
    console.log("clipboard controller copy");
    e.preventDefault();
    const value = this.sourceTarget.textContent.trim();
    navigator.clipboard.writeText(value);
    console.log("copied to clipboard", value);
  }
}
