import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["empty", "pending", "filled"];
  static values = {
    sgid: String,
  };
  connect() {
    this.__state = "unfavorited";
    this.element.classList.add(...this.emptyClasses);
    this.check();
  }

  check() {
    fetch(`/favorites/${this.sgidValue}`, {
      method: "HEAD",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 200) {
        this.element.classList.remove(...this.emptyClasses);
        this.element.classList.add(...this.filledClasses);
        this.__state = "favorited";
      } else {
        this.element.classList.remove(...this.filledClasses);
        this.element.classList.add(...this.emptyClasses);
        this.__state = "unfavorited";
      }
    });
  }

  toggle(event) {
    event.preventDefault();

    this.element.classList.add(...this.pendingClasses);
    if (this.__state === "unfavorited") {
      this.makeFavorite(event);
    } else {
      this.removeFavorite(event);
    }
  }

  removeFavorite(event) {
    const csrfTokenElement = document.querySelector("meta[name='csrf-token']");
    fetch(`/favorites/${this.sgidValue}`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfTokenElement ? csrfTokenElement.content : "",
      },
    }).then((response) => {
      this.element.classList.remove(...this.pendingClasses);
      if (response.status === 204) {
        this.element.classList.remove(...this.filledClasses);
        this.element.classList.add(...this.emptyClasses);
        this.__state = "unfavorited";
      }
      this.check();
    });
  }

  makeFavorite(event) {
    const csrfTokenElement = document.querySelector("meta[name='csrf-token']");
    fetch(`/favorites`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfTokenElement ? csrfTokenElement.content : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sgid: this.sgidValue }),
    }).then((response) => {
      this.element.classList.remove(...this.pendingClasses);
      if (response.status === 201) {
        this.element.classList.remove(...this.emptyClasses);
        this.element.classList.add(...this.filledClasses);
        this.__state = "favorited";
      }
      this.check();
    });
  }
}
