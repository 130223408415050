import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clear-input"
export default class extends Controller {
  static targets = ["input"];
  connect() {
    console.log("Clear input controller connected");
  }

  call(event) {
    console.log("Clear input controller called");
    this.inputTarget.value = "";
    this.inputTarget.focus();
  }
}
