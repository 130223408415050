import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notification--inject"
export default class extends Controller {
  static targets = ["content"];
  static values = {
    title: String,
    message: String,
    icon: String,
    url: String,
  };
  async connect() {
    const isPageVisible = document.visibilityState === "visible";
    console.log("inject controller connected", {
      title: this.titleValue,
      body: this.bodyValue,
      icon: this.iconValue,
      url: this.urlValue,
      isPageVisible,
      visibilityState: document.visibilityState,
    });

    const hasApproved = await this.askForApproval();
    if (!hasApproved) {
      console.log("notification permission denied, fall back to regular notification");
    }

    const isNativeDisabled = true;

    if (!isNativeDisabled && !isPageVisible && (await this.askForApproval())) {
      this.showFancyNotification(this.titleValue, this.messageValue, this.iconValue, this.urlValue);
    } else {
      const tray = document.getElementById("notification_try_container");
      if (!tray) {
        console.log('no notification tray found with id "notification_try_container"');
        return;
      }
      console.log(" found notification tray, appending ", this.contentTarget);
      tray.appendChild(this.contentTarget);
      console.log("removeing self", this.element);
      this.element.remove();
    }
  }
  disconnect() {
    console.log("inject controller disconnected");
  }

  async showFancyNotification(title, body, icon, url) {
    if (!url) {
      this.showNotification(title, body, icon);
    } else {
      const reg = await navigator.serviceWorker.ready;
      reg.showNotification(title, {
        body,
        actions: [{ action: "viewEvent", title: "View", icon }],
        icon,
        badge: icon,
        data: {
          url: url,
        },
      });
    }
  }

  async showNotification(title, body, icon) {
    console.log("show notification", { title, body, icon });

    const notification = new Notification(title, {
      body,
      icon,
      badge: icon,
    });
  }

  async askForApproval() {
    return false;
    if (!window.Notification) {
      return false;
    }
    console.log("ask for approval");
    if (Notification.permission === "granted") {
      return true;
    }

    console.log("requesting permission");
    const permission = await Notification.requestPermission();

    console.log("permission", permission);
    return permission === "granted";
  }
}

// navigator.serviceWorker.register("/sw.js");
