export default class {
  static handle(message, element) {
    console.log(message, element);

    //TODO change here

    let dialog = null;
    if (element.dataset.turboConfirmType == "danger") {
      dialog = document.getElementById("modal-dialog-turbo-danger");
      window.dispatchEvent(new CustomEvent("modal-open-turbo-danger"));
      console.log("event was dispatched to danger ");
    } else {
      dialog = document.getElementById("modal-dialog-turbo");
      window.dispatchEvent(new CustomEvent("modal-open-turbo"));
      console.log("event was dispatched ");
    }

    console.log("dialog", dialog);
    window.elem = element;
    window.dialog = dialog;

    // set title
    const title = dialog.querySelector(".mana-modal-title");
    title.textContent = message;
    // set content
    const content = dialog.querySelector(".mana-modal-content");
    if (element.dataset.turboConfirmHtml) {
      content.innerHTML = document.querySelector(element.dataset.turboConfirmHtml).innerHTML;
      console.log("deailt", "html");
    } else if (element.dataset.turboConfirmDetail) {
      content.innerHTML = element.dataset.turboConfirmDetail;
      console.log("deailt", "detail");
    } else {
      content.innerHTML = "";
      console.log("deailt", "nothing");
    }

    // set buttons
    const confirm = dialog.querySelector('.mana-modal-actions button[value="confirm"] span');
    confirm.innerHTML = element.dataset.turboConfirmYes || "Yes";
    const cancel = dialog.querySelector('.mana-modal-actions button[value="cancel"] span');
    cancel.innerHTML = element.dataset.turboConfirmNo || "No";

    return new Promise((resolve, reject) => {
      dialog.addEventListener(
        "modal-close-turbo",
        (event) => {
          try {
            const result = event.detail.returnValue;
            console.log("modal-dialog-closed-turbo result", result);
            console.log("modal-dialog-closed-turbo resolve", result === "confirm");
            resolve(result === "confirm");
          } catch (error) {
            reject(error);
          }
        },
        { once: true },
      );
    });
  }
}
