import { Controller } from "@hotwired/stimulus";

import Dropzone from "dropzone";
Dropzone.autoDiscover = false;
// import 'dropzone/dist/min/dropzone.min.css'
// import 'dropzone/dist/min/basic.min.css'
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    maxFiles: Number,
    acceptedFiles: String,
    previewsContainerQuery: String,
  };

  connect() {
    this.hiddenInputs = {};
    console.log("DropzoneController connect");
    this.inputTarget.disable = true;
    this.inputTarget.style.display = "none";
    this.dropzone = new Dropzone(this.element, {
      url: "/",
      maxFiles: this.maxFilesValue,
      maxFilesize: "10",
      addRemoveLinks: true,
      thumbnailWidth: 500,

      capture: true,
      thumbnailHeight: 500,
      previewsContainer: this.previewsContainerQueryValue,
      acceptedFiles: this.acceptedFilesValue,
      autoQueue: false,
    });

    this.dropzone.on("removedfile", (file) => {
      console.log("DZ::removedfile", this.element, this.dropzone.element);
      const inputToRemove = this.hiddenInputs[file];
      inputToRemove.remove();
      delete this.hiddenInputs[file];
    });

    this.dropzone.on("addedfile", (file) => {
      console.log("DZ::addedfile", this.element, this.dropzone.element);
      if (this.dropzone.files.length > this.maxFilesValue) {
        this.dropzone.removeFile(this.dropzone.files[0]);
      }

      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url);
          upload.create((error, blob) => {
            const hiddenInput = document.createElement("input");
            this.hiddenInputs[file] = hiddenInput;
            hiddenInput.type = "hidden";
            hiddenInput.name = this.inputTarget.name;
            hiddenInput.value = blob.signed_id;

            this.inputTarget.parentNode.insertBefore(hiddenInput, this.inputTarget.nextSibling);
            this.dropzone.emit("success", file);
            this.dropzone.emit("complete", file);
          });
        }
      }, 500);
    });
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }
}
