import { Controller } from "@hotwired/stimulus";

// TODO: https://stackoverflow.com/questions/27980084/scrolling-to-a-element-inside-a-scrollable-div-with-pure-javascript

const SPEED = 250;

// Connects to data-controller="util--scroll-to"
export default class extends Controller {
  static values = { flash: Boolean, class: String, container: String };
  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.scroll);
  }

  scroll(event) {
    const id = this.element.hash.replace(/^#/, "");
    const target = document.getElementById(id);
    const container = document.getElementById(this.containerValue || "main_container");

    if (target && target.offsetTop !== undefined && container && container.scrollTop !== undefined) {
      console.log("scroll to", target, target.offsetTop);
      container.scrollTop = target.offsetTop - 148;
      event.preventDefault();
      this.classValue = "bg-yellow-100";
      this.flash(id);
    } else {
      console.log({
        target,
        container,
      });
    }

    // if (target && target.scrollIntoView) {
    //   target.scrollIntoView({ behavior: "smooth" });
    //   setTimeout(() => {
    //     window.location.hash = id;
    //     if (this.flashValue) {
    //       this.flash(id);
    //     }
    //   }, 250);
    // } else {
    //   console.log("No target or unsupported", target, id);
    // }
  }

  flash(id) {
    const target = document.getElementById(id);
    if (target) {
      target.classList.add(this.classValue);
      setTimeout(() => {
        target.classList.remove(this.classValue);
        setTimeout(() => {
          target.classList.add(this.classValue);
          setTimeout(() => {
            target.classList.remove(this.classValue);
          }, SPEED);
        }, SPEED);
      }, SPEED);
    }
  }
}
